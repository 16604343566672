<template>
  <div class="row justify-content-center mb-4">
    <div class="col-12 text-center big_bold my-4">
      See how your donations can benefit lives each month:
    </div>
    <div class="col-md-10">
      <div class="row justify-content-center">
        <div class="col-12 col-sm-6 col-lg-4 mb-2" v-for="video, index in youTubeVideos" :key="index">
          <YouTubeVideo :link="video" :height="220"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    YouTubeVideo: defineAsyncComponent(() => import('@/components/YouTubeVideo.vue'))
  },
  name: 'Daily Sadaqah YouTube Section',
  data () {
    return {
      youTubeVideos: [
        'xaqJVSTS89E',
        'LDzW5w3drPw',
        'IXmGZrkb5-w',
        'QWAW1MahwpU',
        'iO43hRVtR_M',
        'LupIz4CZ3Ls'
      ]
    }
  }
}
</script>

<style scoped>
.big_bold {
  color: var( --green-color-dark );
  font-family: "quicksand_bold", Sans-serif;
  font-size: 1.9rem;
  font-weight: 700;
}
</style>
